*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Gatwick', sans-serif;
  color: #1b1f23;
  line-height: 1.8;
  font-weight: 400;
  background-color: #cfd9df;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
